import React from "react" 
import { Link, graphql } from "gatsby"

import Layout from "components/layout.js"

// export  default 
export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout 
    // location={this.props.location} 
    // title={siteTitle}
    > 
      
      <div className="jumbotron page-header">
        <div className="container">
          <h1 className="display-32">Blog</h1>
        </div>
      </div>
      <div className="container"> 
        <h1>{frontmatter.title}</h1>
        <div className="blog-post-container blog">
          <div className="blog-post">
            {/* <h1>{frontmatter.title}</h1> */}
            {/* <h2>{frontmatter.date}</h2> */}
              
          <p
            style={{
              // ...scale(-1 / 5),
              display: `block`,
              // marginBottom: rhythm(1),
              // marginTop: rhythm(-1),
            }}
          >
            {frontmatter.date}
          </p>
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
            {/* {html} */}
          </div>
        </div>
      </div>
      </Layout>
  )
}



export class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    // const siteTitle = this.props.data.site.siteMetadata.title
    // const { previous, next } = this.props.pageContext

    return (
      <Layout 
      // location={this.props.location} title={siteTitle}
      >
        
        
        <div className="jumbotronn page-header">
          <div className="container">
            <h1 className="display-32">Blog 5</h1>
          </div>
        </div>
        <div className="container"> 
        <div className="row"><div className="col-md-12">
        <h1>{post.frontmatter.title}</h1>
        <p
          style={{
            // ...scale(-1 / 5),
            display: `block`,
            // marginBottom: rhythm(1),
            // marginTop: rhythm(-1),
          }}
        >
          {post.frontmatter.date}
        </p>
        <p
          style={{
            // ...scale(-1 / 5),
            display: `block`,
            // marginBottom: rhythm(1),
            // marginTop: rhythm(1),
          }}
        >
          {/* Written by: <strong>{post.frontmatter.author.id || ''}</strong> -{` `}
          {post.frontmatter.author.bio || ''} */}
        </p>
        <div dangerouslySetInnerHTML={{ __html: post.html }} ></div>
        {/* <div>{post.html}</div> */}
        {/* <hr
          style={{
            // marginBottom: rhythm(1),
          }}
        /> */}
{/* 
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul> */}
        </div></div>
        </div>
      </Layout>
    )
  }
}

// export BlogPostTemplate



export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } , published: { eq: true } }) {
      html
      id
      excerpt(pruneLength: 160)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        slug
        published
      }
    }
  }
` 

/*
site {
  siteMetadata {
    title
  }
}
      fields {
        slug
        date
      }
*/      
/* 


export const pageQuery = graphql`
  query CorpWWWBlogPluginBlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
      fields {
        slug
        date
      }
  
    }
  }
`   */
        // author {
        //   id
        //   bio
        // }